<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Quick Registration</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">



                <div class="form-group first">
                    <label for="username">Name</label>
                    <input name="username" type="text" class="form-control" v-model="username" placeholder="" id="">

                </div>
                <div class="form-group last mb-3">
                    <label for="email">Email</label> <span class="mb-4" style="margin-left: 52%;">You will recieve OTP on your Email.</span>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="" id="">

                </div>
                <div class="form-group last mb-3">
                    <label for="phone">Phone</label>
                    <input name="phone" type="phone" class="form-control" v-model="phone" placeholder="" id="">

                </div>
                <div class="form-group last mb-3">
                    <label for="password">Create Password</label>
                    <input name="password" type="password" class="form-control" v-model="password" placeholder="" id="">

                </div>

                <input v-on:click="Submit" type="submit" value="Register Now" class="btn btn-common">
               

            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>
   
</template>

<script >
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 
export default {
        name: 'QuickReg',
        data() {
            return {
                username: '',
                email: '',
                phone: '',
                password: ''
            }
        },
        methods: {
            async Submit() {
				axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
				axios.defaults.headers.post['Accept'] = 'application/json';
                const submit_data = {
                    name: this.username,
                    email: this.email,
                    mobile: this.phone,
                    password: this.password,
                    user_type: 19
                }
               
                let result = await axios.post("/app/register_user2/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", submit_data);
                if (result.data.status == true) {
                    sessionStorage.setItem("email", this.email);
                    sessionStorage.setItem("phone", this.phone);
                    this.$router.push('/OTPVerify');
                } else {
					this.$router.push('/LogIn');
                    /*if(result.data.message == "VERIFIED"){this.$router.push('/RegBus');}
					else{
						const para = {
									user_id: result.data.data,
						};
						let buss = await axios.post("/app/registration_detail/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", para);
						sessionStorage.setItem("login_details",JSON.stringify(buss.data));
						this.$router.push('/RegSuccess');
					}*/
                }
                
            }
        }
    }
</script>