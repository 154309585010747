<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Registration Successful</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-list text-white"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                <a href="https://haspatal.com/pharmacies-faq/" class="btn btn-light">Frequently Asked Question</a><a href="https://haspatal.com/meet-our-pharmacy-page-6/" class="btn btn-light ml-1">Meet Our Pharmacies</a>
            </form>
        </div>
    </nav>

    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <div class="col-md-10">
                    <table class="table table-bordered bg-white">
                        <thead class="btn-light">
                            <tr>
                                <th width="30%">Label</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>Merchant Name</b></td>
                                <td id="source"></td>
                            </tr>
                            <tr>
                                <td><b>Merchant ID</b></td>
                                <td class="text-common" id="M_ID"></td>
                            </tr>
                            <tr>
                                <td><b>Registered On</b></td>
                                <td id="created_at">17 - Oct - 2023 ,4:40 PM</td>
                            </tr>
                            <tr>
                                <td><b>Location</b></td>
                                <td id="address">Delhi,New Delhi District, Karol bagh</td>
                            </tr>
                            <tr>
                                <td><b>Pincode</b></td>
                                <td id="pincode">110005</td>
                            </tr>
                            <tr>
                                <td><b>Selected Plan</b></td>
                                <td>Basic Plan</td>
                            </tr>
                            <tr>
                                <td><b>Present Status</b></td>
                                <td id="reg_status1">
                                     <span class="text-danger">Approval Pending <i class="fa fa-hourglass"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/img1.PNG" /></div>
            </div>
        </div>
    </div>

</template>
<script>
  import $ from 'jquery';
   
   export default {
                name: 'RegSuccess',
                mounted: function(){
                this.SetValue()
             },
             methods:{
                SetValue(){
                        const data = JSON.parse(sessionStorage.getItem('login_details'));
                        console.log(data.data);
                        
                        $('#M_ID').html(data.data.business_registerID);
                        $('#source').html(data.data.conatact_person);
                        $('#created_at').html(data.data.buss_reg_date);
                        $('#address').html(data.data.address);
                        $('#pincode').html(data.data.pincode);
						$('#reg_status').html('<span class="text-common">'+ data.data.reg_status +'</span>')
                        if(data.data.reg_status == "Approval Pending"){
                            //$('#reg_status').html('<span class="text-success">Approved <i class="fa fa-check-circle"></i></span>')
                        }else{
                            //$('#reg_status').html('<span class="text-danger">Pending <i class="fa fa-hourglass"></i></span>')
                        }
                        
                        

                        
                         
                }
             }
            }
             </script>